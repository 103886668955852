import React, { useState } from "react";
import { useAuth } from "../hooks/AuthProvider";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Layout/Sidebar";
import { Flex, Box, Heading, Text } from "@chakra-ui/react";
import { useTheme } from "@chakra-ui/react";

import TextRequirementsPanel from "../components/panels/textRequirementsPanel";
import ProfilePanel from "../components/panels/profilePanel";
import VariablesPanel from "../components/panels/variablesPanel";

const Home = () => {
  const { user, logOut } = useAuth();
  const navigate = useNavigate();
  const [activePanel, setActivePanel] = useState("variables"); // Track which panel is active
  const [panelWidth, setPanelWidth] = useState(100); // Panel width in percentage
  const theme = useTheme();

  const handleLogout = () => {
    logOut();
    navigate("/login");
  };

  const handleDrag = (e) => {
    const newWidth = (e.clientX / window.innerWidth) * 100;
    if (newWidth > 20 && newWidth < 100) {
      setPanelWidth(newWidth);
    }
  };

  const stopDrag = () => {
    document.removeEventListener("mousemove", handleDrag);
    document.removeEventListener("mouseup", stopDrag);
  };

  const startDrag = () => {
    document.addEventListener("mousemove", handleDrag);
    document.addEventListener("mouseup", stopDrag);
  };

  return (
    <Flex height="100vh">
      {/* Sidebar */}
      <Sidebar onPanelSelect={setActivePanel} />

      {/* Dynamic Panel Content */}
      {activePanel && (
        <Box
          width={`${panelWidth}%`}
          bg={theme.colors.vscode.background}
          color="white"
          padding="20px"
          overflowY="auto"
          boxShadow="lg"
        >
          {activePanel === "textRequirements" && <TextRequirementsPanel />}
          {activePanel === "profile" && (
            <Box>
              <Heading>Welcome, {user?.username || "User"}!</Heading>
              <Text>Email: {user?.email}</Text>
              <Text marginTop="10px">
                Select other options from the sidebar to explore panels.
              </Text>
            </Box>
          )}
          {activePanel === "variables" && <VariablesPanel />}
          {activePanel === "settings" && <Text>Settings Panel Content</Text>}
          {activePanel === "chat" && <Text>Your Chat</Text>}
          {activePanel === "flowDiagram" && <Text>Your Flow Diagram</Text>}
        </Box>
      )}

      {/* Draggable Divider */}
      {activePanel && (
        <Box
          width="5px"
          bg="gray.500"
          cursor="col-resize"
          onMouseDown={startDrag}
          zIndex="10"
        />
      )}

      {/* Empty Space or Main Content */}
     
    </Flex>
  );
};

export default Home;

