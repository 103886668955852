import apiClient from "./apiClient";

/**
 * Perform login API call.
 * @param {Object} data - The file you want to upload.
 * @returns {Object} - Returns message if successful.
 */

export const upload_file = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await apiClient.post('/upload', formData, false);
  return response;
};