import apiClient from "./apiClient";

/**
 * Perform login API call.
 * @param {Object} data - The login credentials { email, password }.
 * @returns {Object} - Returns user and token if successful.
 * @throws {Error} - Throws an error if login fails.
 */
export const loginAction = async (data) => {
  try {
    const response = await apiClient.post("/users/login", data); // Call Flask API
    const { token, user } = response;

    // Save token and user to localStorage
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));

    return { token, user };
  } catch (error) {
    console.error("Login failed:", error.message);
    throw error;
  }
};

/**
 * Perform logout logic.
 */
export const logoutAction = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};
