import { BASE_URL } from '../config';

const apiClient = {
  async get(endpoint) {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    if (!response.ok) throw new Error(`Error: ${response.status}`);
    return response.json();
  },
  
  async post(endpoint, data, isFileResponse = false) {
    const options = {
      method: 'POST',
      body: data instanceof FormData ? data : JSON.stringify(data),
    };

    // Only set headers for JSON data; FormData will set its own headers
    if (!(data instanceof FormData)) {
      options.headers = { 'Content-Type': 'application/json' };
    }

    const response = await fetch(`${BASE_URL}${endpoint}`, options);

    if (!response.ok) throw new Error(`Error: ${response.status}`);

    // Check if the response is a file
    if (isFileResponse) {
      return response.blob(); // Return the file as a Blob
    }

    return response.json();
  },

  // Additional methods (PUT, DELETE) can be added as needed
};
export default apiClient;