import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import ProtectedRoute from './ProtectedRoute';
import AuthProvider from './hooks/AuthProvider';

const AppRoutes = () => {
  const isAuthenticated = localStorage.getItem('token'); // Example: Check token for authentication

  return (
    <Router>
        <AuthProvider>
      <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
    />
      </Routes>
      </AuthProvider>
    </Router>
  );
};

export default AppRoutes;
