import React, { useState } from "react";
import { Box, Button, Text, Heading,Table, Tbody, Td, Th, Thead, Flex, Tr, Stack, FormControl, FormLabel, Input, VisuallyHiddenInput, IconButton, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Tooltip, Checkbox} from "@chakra-ui/react";
import { upload_file } from "../../api/upload";
import { download_file } from "../../api/dowload";
import {DeleteIcon, EditIcon, PlusSquareIcon} from "@chakra-ui/icons";



const VariablesPanel = () => {
    
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [variables, setVariables] = useState([]);
  const [validationMessage, setValidationMessage] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectedVariables, setSelectedVariables] = useState([]);

  const isAllSelected = selectedVariables.length === variables.length;
  const isIndeterminate =
    selectedVariables.length > 0 && selectedVariables.length < variables.length;

    console.log(variables)
    // Add new variable
    const handleAddRow = () => {
        const newVariable = {
          variable_name: "",
          type: "",
          port: "",
          default_value: "",
        };
      
        setVariables((prev) => {
          const updatedVariables = [...prev, newVariable];
          setEditingIndex(updatedVariables.length - 1); // Set the index of the new row
          onOpen(); // Open the modal
          return updatedVariables;
        });
      };
      

  // Handle row checkbox change
  const handleRowSelect = (variableName) => {
    setSelectedVariables((prevSelected) =>
      prevSelected.includes(variableName)
        ? prevSelected.filter((name) => name !== variableName)
        : [...prevSelected, variableName]
    );
  };

  // Handle select all
  const handleSelectAll = (isChecked) => {
    setSelectedVariables(isChecked ? variables.map((v) => v.variable_name) : []);
  };

  // Handle massive delete
  const handleMassiveDelete = () => {
    setVariables((prev) =>
      prev.filter((v) => !selectedVariables.includes(v.variable_name))
    );
    setSelectedVariables([]); // Clear the selection
  };
  //porco dio
  const handleFileChange = (e) => setFile(e.target.files[0]);

  const handleInputChange = (index, field, value) => {
    const updatedVariables = [...variables];
    updatedVariables[index][field] = value;
    setVariables(updatedVariables);
    setValidationMessage("");
  };

  const handleValidationCheck = () => {
    if (!arePortsValid()) {
      setValidationMessage("Every port must be a valid number.");
    }
  };
  const handleRemoveVariable = (index) => {
    const updatedVariables = variables.filter((_, i) => i !== index);
    setVariables(updatedVariables);
  };
  const handleEditRow = (index) => {
    setEditingIndex(index);
    onOpen(); // Open modal
  };

  const handleSaveEdit = () => {
    setEditingIndex(null);
    onClose();
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      handleFileChange(event);
    }
    setValidationMessage("");
  };



  const handleSend = async () => {
    setValidationMessage("");

    try{
        const blob = await download_file(variables);
        
        console.log(blob);
        setMessage('File blob successfully!');
        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);
        // Create a link element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'default.erd'; // Use the filename suggested in the response header
        a.click(); // Simulate a click to download the file

        // Revoke the URL after download to free up memory
        window.URL.revokeObjectURL(url);

    }
    catch (error) {
        alert("Error sending updated JSON.");
        console.error(error);

    }
  };

  const arePortsValid = () => {
    return variables.every(
      (variable) => !isNaN(variable.port) && variable.port !== ""
    );
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage('Please select a file');
      return;
    }

    try {
      console.log(file);
      const response = await upload_file(file);
      console.log(JSON.stringify(response))
      if(response){
        setVariables(response); // Assuming backend returns the variables as JSON
        console.log(variables)
        setMessage("File processed successfully! Variables loaded.");
      } else {
        setMessage("Error processing the file.");
      }

      // setMessage('Variables received successfully!');
    } catch (error) {
      setMessage(`Error 2: ${error.message}`);
    }

  };

  return (
    
    <Box padding= {10}>
    <Box padding={10} borderWidth='1px' borderRadius='lg' overflow='hidden'width="50%" >
      <Heading size="md" mb={4}>
        Upload XLSX File
      </Heading>
      <Text fontSize="sm" mb={4}>
        Select an XLSX file to upload. Ensure the file format is correct.
      </Text>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2} direction={["column", "row"]} align="center" alignItems={"center"}>
          <FormControl>
            <Flex align="center" bg="vscode.sidebar" p={3} rounded="md" mb={4} width="500px">
            <VisuallyHiddenInput
              id="file"
              type="file"
              onChange={handleFileSelect}
              accept=".xlsx"
              size="md"
              variant="filled"
              bg="vscode.sidebar"
              p={2}
              
              color="gray.200"
              _hover={{ bg: "gray.600" }}
              width="500px"
            />
            <Button
            as="label"
            htmlFor="file"
            bg="vscode.foreground"
            size="sm"
            cursor="pointer"
            _hover={{ bg: "gray.600" }}
            >
            Select File
            </Button>
            <Text
            ml={3}
            fontSize="sm"
            color="gray.500"
            noOfLines={1}
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            >
            {fileName || "No file chosen"}
            </Text>
      </Flex>
          </FormControl>
          <Button
            type="submit"
            bg="xelerit.lightBlue"
            color="white"
            size="md"
            isLoading={isUploading}
            loadingText="Uploading"
            width="100px"
          >
            Convert
          </Button>
        </Stack>
      </form>
      {message && (
        <Text mt={4} fontSize="sm">
          {message}
        </Text>
      )}
    </Box>
    <Box padding={10} borderRadius='lg' bg="vscode.sidebar" marginTop="20px">   
    <Flex justify="space-between" mb={4}>
          <Heading size="sm" color="white">
            Variables Table
          </Heading>

          <Flex justify="flex-end" >
          <Button
                backgroundColor={arePortsValid() ? "xelerit.lightBlue" : "gray.400"}
                size="sm"
                marginRght= "10px"
                color="white"
                isDisabled={variables.length === 0}
                onClick={() => {
                if (arePortsValid()) {
                    handleSend();
                } else {
                    handleValidationCheck();
                }
                }}
            >
            Download File
            </Button>

            <Button
                colorScheme="red"
                marginLeft={2}
                size="sm" 
                onClick={handleMassiveDelete}
                isDisabled={selectedVariables.length === 0}
            >
                Delete Selected ({selectedVariables.length})
            </Button>
            <Button color="vscde.foreground" size="sm" onClick={handleAddRow} mr={4} marginLeft={2}>
            <PlusSquareIcon color="black" />
          </Button>
          </Flex>

        </Flex>
        {validationMessage && (
        <Text color="red.500" marginTop="10px">
          {validationMessage}
    </Text> )}
    <Table variant="simple" >
    <Thead
      position="sticky"
      top={0}
      zIndex={1}
      bg="vscode.sidebar"
      color="white"
      boxShadow="sm"
    >
            <Tr>
            <Th>
              <Checkbox
                isChecked={isAllSelected}
                isIndeterminate={isIndeterminate}
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
            </Th>
              <Th fontSize="lg" py={4} px={6} color="white" >Variable Name</Th>
              <Th fontSize="lg" py={4} px={6}color="white" >Type</Th>
              <Th fontSize="lg" py={4} px={6} color="white" >Port</Th>
              <Th fontSize="lg" py={4} px={6} color="white" >Default Value</Th>
              <Th fontSize="lg" py={4} px={6} color="white" >Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {variables.map((variable, index) => (
               <Tr key={index}  >
                <Td>
                  <Checkbox
                    isChecked={selectedVariables.includes(variable.variable_name)}
                    onChange={() => handleRowSelect(variable.variable_name)}
                  />
                </Td>
               <Td>{variable.variable_name}</Td>
               <Td>{variable.type}</Td>
               <Td color={!isNaN(variable.port) && variable.port !== "" ? "white" : "red"} >{variable.port}</Td>
               <Td>{variable.default_value}</Td>
                <Td>
                  <Tooltip label="Edit Variable" aria-label="Edit Variable">
                    <IconButton
                      size="sm"
                      icon={<EditIcon />}
                      onClick={() => handleEditRow(index)}
                      mr={2}
                    />
                  </Tooltip>
                  <Tooltip label="Remove Variable" aria-label="Remove Variable">
                    <IconButton
                      size="sm"
                      icon={<DeleteIcon />}
                      colorScheme="red"
                      onClick={() => handleRemoveVariable(index)}
                    />
                  </Tooltip>
                </Td>
              </Tr>
            ))}
          </Tbody>
    </Table>
    </Box>
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay  />
        <ModalContent bg="vscode.sidebar">
          <ModalHeader>Edit Variable</ModalHeader>
          <ModalBody>
            {editingIndex !== null && (
              <>
                <Input
                  mb={3}
                  placeholder="Variable Name"
                  value={variables[editingIndex].variable_name}
                  onChange={(e) =>
                    handleInputChange(editingIndex, "variable_name", e.target.value)
                  }
                />
                <Input
                  mb={3}
                  placeholder="Type"
                  value={variables[editingIndex].type}
                  onChange={(e) =>
                    handleInputChange(editingIndex, "type", e.target.value)
                  }
                />
                <Input
                  mb={3}
                  placeholder="Port"
                  value={variables[editingIndex].port}
                  onChange={(e) =>
                    handleInputChange(editingIndex, "port", e.target.value)
                  }
                />
                <Input
                  placeholder="Default Value"
                  value={variables[editingIndex].default_value}
                  onChange={(e) =>
                    handleInputChange(editingIndex, "default_value", e.target.value)
                  }
                />
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSaveEdit}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  
  );
};

export default VariablesPanel;
