import apiClient from "./apiClient";

/**
 * Perform login API call.
 * @param {Object} data - The file you want to upload.
 * @returns {Object} - Returns message if successful.
 */

export const download_file = async (data) => {
  const response = await apiClient.post('/download', data, true);
  return response;
};