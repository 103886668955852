import { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useAuth } from "../hooks/AuthProvider"; // Import the useAuth hook
import{Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [input, setInput] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState(null); 
  const auth = useAuth(); 
  const navigate = useNavigate();

  const handleShowClick = () => setShowPassword(!showPassword);

  const handleSubmitEvent = async (e) => {
    e.preventDefault(); 
    setError(null); 

    if (input.username !== "" && input.password !== "") {
      try { 
        await auth.loginAction(input); 
        navigate("/home");
        }
        catch (error) {
    alert("Please provide a valid input")
}
  }
};

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="xelerit.background" // Custom background color
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="xelerit.lightBlue" /> {/* Avatar with primary color */}
        <Heading color="xelerit.lightBlue">Welcome to Xelerit</Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleSubmitEvent}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="vscode.sidebar" // Custom card background
              boxShadow="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="vscode.accent" />} // Accent color for icon
                  />
                  <Input
                    type="text"
                    placeholder="Username"
                    value={input.username}
                    onChange={(e) =>
                      setInput({ ...input, username: e.target.value })
                    }
                    required
                    color="vscode.foreground" // Foreground text color
                    bg="vscode.sidebar" // Background for input
                    _placeholder={{ color: "vscode.secondary" }} // Placeholder color
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaLock color="vscode.accent" />} // Accent color for icon
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={input.password}
                    onChange={(e) =>
                      setInput({ ...input, password: e.target.value })
                    }
                    required
                    color="vscode.foreground" // Foreground text color
                    bg="vscode.sidebar" // Background for input
                    _placeholder={{ color: "vscode.secondary" }} // Placeholder color
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handleShowClick}
                      colorScheme="vscode.primary"
                    >
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              {error && <Text color="vscode.error">{error}</Text>} {/* Error Message */}
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                bg="xelerit.lightBlue" // Button background
                color="white" // Button text color
                _hover={{ bg: "vscode.accent" }} // Hover effect
                width="full"
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;


